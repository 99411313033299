import React from 'react';
import Link from 'gatsby-link';
import styled from 'styled-components';

const Alert = styled.div`
	${tw`bg-teal-lightest border-t-4 border-teal rounded-b text-teal-darkest px-4 py-3 shadow-md`};

	.title {
		${tw`flex text-white rounded-full bg-primary uppercase px-2 py-1 text-xs font-bold mr-3`};
	}
	.text {
		${tw`font-semibold mr-2 text-left flex-auto`};
	}
	.svg {
		${tw`fill-current  h-4 w-4`};
	}
`;

export default function Coffee() {
	return (
		<Alert role="alert">
			<Link to="coffee">
				<span className="title">Holy Smokes!!</span>
				<span className="text">You found my top secret coffee recipe... Click to learn it.</span>
				<svg className="svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
					<path d="M12.95 10.707l.707-.707L8 4.343 6.586 5.757 10.828 10l-4.242 4.243L8 15.657l4.95-4.95z" />
				</svg>
			</Link>
		</Alert>
	);
}
