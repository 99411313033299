import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Link from 'gatsby-link';
import Img from 'gatsby-image';

import styled from 'styled-components';
import Template from '../layouts/Template';
import Coffee from '../components/Coffee';

class Index extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showCoffee: false,
		};
		this.show = this.show.bind(this);
	}

	show() {
		this.setState({ showCoffee: true });
		setTimeout(() => this.setState({ showCoffee: false }), 5000);
	}

	render() {
		const { showCoffee } = this.state;
		const { data } = this.props;
		const { edges } = data.allMarkdownRemark;

		return (
			<Template title={data.site.siteMetadata.title} desc="Hi there! I'm Tyler.">
				<StyledBanner>
					<div className="container">
						<div className="hero">
							<h1 className="hero__title">
								I am a systems design architect and practitioner with a passion for solving business problems with modern
								technology.
							</h1>
							<div className="hero__image">
								<img
									src="/coffee-white.png"
									alt="Coffee Mug"
									role="presentation"
									onClick={this.show}
									width="155px"
									height="203px"
								/>
							</div>
						</div>
					</div>
				</StyledBanner>
				{showCoffee && <Coffee />}

				<BlogReel>
					<header className="underline">
						<div className="underline__wrapper">
							<h2 className="underline__title">Latest Blog Posts</h2>
						</div>
					</header>
					<div className="container">
						{edges.map(({ node }) => (
							<PostLink className="teaser" key={node.fields.slug}>
								<div className="teaser__image">
									<Link to={node.fields.slug}>
										<Img fixed={node.frontmatter.featuredImage.childImageSharp.fixed} />
									</Link>
								</div>
								<div className="teaser__text">
									<Link to={node.fields.slug}>
										<h3 className="teaser__title">{node.frontmatter.title}</h3>
										<div className="teaser__subtitle date">{node.frontmatter.date}</div>
									</Link>
								</div>
							</PostLink>
						))}
						<Link to="/blog/" className="button">
							VIEW ALL
						</Link>
					</div>
				</BlogReel>
			</Template>
		);
	}
}

Index.propTypes = {
	data: PropTypes.object.isRequired,
};
export default Index;

export const pageQuery = graphql`
	query IndexQuery {
		site {
			siteMetadata {
				title
			}
		}
		allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }, limit: 3) {
			edges {
				node {
					fields {
						slug
					}
					frontmatter {
						title
						date(formatString: "MMMM DD, YYYY")
						featuredImage {
							childImageSharp {
								fixed(width: 275, height: 170) {
									...GatsbyImageSharpFixed
								}
							}
						}
					}
				}
			}
		}
	}
`;

const StyledBanner = styled.section`
	.hero {
		${tw`flex flex-wrap`};
		&__title {
			${tw`leading-normal tracking-medium items-center my-3 text-24 lg:text-36 lg:w-2/3`};
		}
		&__image {
			${tw`flex justify-center items-center lg:w-1/3`};
		}
	}
`;

const BlogReel = styled.div`
	${tw`bg-white py-8 pt-16 pb-24`};
`;
const PostLink = styled.div`
	${tw`mb-12 flex flex-wrap md:flex-no-wrap`};
	.teaser {
		&__image {
			${tw`md:max-w-1/4 mb-2 md:mb-0 overflow-hidden`};
		}
		&__text {
			${tw`md:pl-6 md:max-w-3/4 flex items-center flex-wrap`};
		}
		&__title {
			${tw`text-30 pb-4`};
		}
		&__subtitle {
			${tw`font-sans uppercase font-bolder text-highlight opacity-100`};
		}
	}
`;
